.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .text {
    font: var(--h1-slim-secondary);
    text-align: center;
  }
}
